/* eslint-disable complexity */
/* eslint-disable no-param-reassign */
/* global yaCounter994527 */

import Vue from 'vue';

const ELEMENT_TYPE_CHECKBOX = 0;

export default (context, inject) => {
	function enrichData(e, data) {
		if (!data.context) {
			data.context = {};
		}
		const ctx = data.context;

		if (typeof window !== 'undefined') {
			if (document.referrer) {
				ctx.referrer = document.referrer;
			}
			ctx.location = window.location.href;

			const clientID = typeof yaCounter994527 !== 'undefined'
				? yaCounter994527.getClientID()
				: '';
			if (clientID) {
				ctx.clientID = clientID;
			}
			if (window.UAClientID) {
				ctx.UAClientID = window.UAClientID;
			}
		}

		try {
			const responseError = context.$api.getErrorMessage(e);
			if (responseError) {
				ctx.responseError = responseError;
				const { config } = e;
				if (config) {
					ctx.baseURL = config.baseURL;
					ctx.url = config.url;
					ctx.params = config.params;

					ctx.error = data.message;
					data.message = config.url;
				}
				data.message += `: ${responseError}`;
			}
		} catch (err) {
			//
		}

		if (e.stack) {
			ctx.stack = e.stack;
		}

		if (typeof navigator !== 'undefined') {
			[
				'appCodeName',
				'appName',
				'appVersion',
				'doNotTrack',
				'cookieEnabled',
				'deviceMemory',
				'hardwareConcurrency',
				'platform',
				'product',
				'productSub',
				'userAgent',
				'userAgentData',
				'vendor',
			].forEach((key) => {
				if (navigator[key]) {
					ctx[key] = navigator[key];
				}
			});
		}

		return data;
	}

	function toBackend(data, e = {}) {
		try {
			context.$api.axios.$post('api/log', enrichData(e, data)).then();
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * Logs all checkbox clicks with data-action
	 * @param {Event} event
	 * @returns
	 */
	function checkboxesDelegateHandler(event) {
		const { target } = event;

		const input = target.closest('input');

		if (!input) return;

		if (input.type.toLowerCase() !== 'checkbox') return;

		// get `action_name` from <input data-action="action_name">
		const { action } = target.dataset;

		if (!action) return;

		try {
			context.$api.axios.$post('api/action', {
				ts: new Date().valueOf(),
				element_type: ELEMENT_TYPE_CHECKBOX,
				id: target.id,
				checked: target.checked,
				name: target.name,
				action,
			});
		} catch (error) {
			console.error(error);
		}
	}

	const logger = {
		info(message, ctx = {}) {
			toBackend({
				level: 'info',
				message,
				context: {
					...ctx,
					from: 'logger',
				},
			});
		},
		warn(message, ctx = {}) {
			toBackend({
				level: 'warning',
				message,
				context: {
					...ctx,
					from: 'logger',
				},
			});
		},
		error(message, ctx = {}) {
			toBackend({
				message,
				context: {
					...ctx,
					from: 'logger',
				},
			});
		},
		exception(e, method = '', vm = null) {
			const m = method ? `${method} ` : '';
			const msg = (vm && vm.$options) ? `${vm.$options.name}@${m}` : m;
			console.error(msg, e);
			toBackend({
				message: `${msg}${e.name}: ${e.message}`,
				context: {
					from: 'logger',
				},
			}, e);
		},
		// legacy
		log(e) {
			if (e === null) {
				return;
			}

			if (process.env.NODE_ENV !== 'production') {
				console.error(e);
			}

			if (typeof e === 'object') {
				this.exception(e);

				if (context.$sentry) {
					context.$sentry.captureException(e);
				}
				return;
			}

			if (typeof e === 'string' && context.$sentry) {
				context.$sentry.captureMessage(e);
			}
		},
	};

	inject('logger', logger);
	// eslint-disable-next-line no-param-reassign
	context.$logger = logger;

	Vue.config.errorHandler = (e, vm, info) => {
		console.error(e);
		const name = (vm && vm.$options) ? vm.$options.name : 'component';
		toBackend({
			message: `${name}@${info}: ${e.name}: ${e.message}`,
			context: {
				from: 'Vue.config.errorHandler',
			},
		}, e);
	};
	Vue.config.warnHandler = (msg, vm, trace) => {
		console.warn(msg);
		const name = (vm && vm.$options) ? vm.$options.name : 'component';
		toBackend({
			level: 'warning',
			message: `${name}: ${msg}`,
			context: {
				stack: trace,
				from: 'Vue.config.warnHandler',
			},
		});
	};

	if (process.client) {
		window.onerror = function onerror(message, source, lineno, colno, error) {
			console.error(message, source, lineno, colno, error);
			toBackend({
				message: `${error.name}: ${message}`,
				context: {
					from: 'window.onerror',
					source,
					lineno,
					colno,
				},
			}, error);
		};

		document.addEventListener('change', checkboxesDelegateHandler);
	}
};
