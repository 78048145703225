<template>
	<div :class="{ 'overflow-hidden': !$route.path.startsWith('/alumni-platform') }">
		<nuxt />
		<panel-cookies
			v-if="isCookiesDisclaimerVisible"
			@accept="onCookiesAccept"
		/>
		<auth v-if="isAuthVisible" />
		<activation v-if="$auth.loggedIn" />
		<search-modal />
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import uuidMixin from '~/mixins/uuidMixin';

import SearchModal from '~/components/layouts/_partials/SearchModal.vue';
import Activation from '~/components/layouts/_partials/Activation.vue';
import PanelCookies from '~/components/dev/PanelCookies.vue';

export default {
	name: 'DefaultLayout',
	components: {
		Auth: () => import('~/components/layouts/_partials/Auth.vue'),
		SearchModal,
		Activation,
		PanelCookies,
	},
	mixins: [uuidMixin],
	computed: {
		...mapState('cookiesDisclaimer', {
			isCookiesAccepted: ({ isCookiesAccepted }) => isCookiesAccepted,
			isCookiesDisclaimerVisible: ({ isVisible }) => isVisible,
		}),
		isAuthVisible: {
			get() {
				return this.$store.state.authModal.isVisible;
			},
			set(visible) {
				this.setIsVisible(visible);
			},
		},
	},
	watch: {
		isAuthVisible: {
			handler(is) {
				if (!this.$ssrContext && is) {
					const { $auth } = this;
					const { path } = this.$route;
					$auth.$storage.setUniversal('redirect', path || '/');
				}
			},
			immediate: true,
		},
		'$route.query.login': {
			handler(is) {
				if (is === 'true') {
					this.showLogin();
					this.$router.push({ ...this.$route, query: { ...this.$route.query, login: undefined } });
				}
			},
			immediate: true,
		},
		'$route.query.reg': {
			handler(is) {
				if (is === 'true') {
					this.showRegistration();
					this.$router.push({ ...this.$route, query: { ...this.$route.query, reg: undefined } });
				}
			},
			immediate: true,
		},
		'$i18n.locale': {
			handler(lang, old) {
				const setLangToDefaults = (axios) => {
					if (!axios.defaults.params) {
						// eslint-disable-next-line no-param-reassign
						axios.defaults.params = {};
					}
					// eslint-disable-next-line no-param-reassign
					axios.defaults.params.lang = lang;
				};
				if (lang !== old) {
					setLangToDefaults(this.$api.axios);
					setLangToDefaults(this.$pagesApi.axios);
					if (typeof window !== 'undefined' && window.moment) {
						window.moment.locale(lang);
					}
				}
			},
			immediate: true,
		},
	},
	created() {
		this.fillUuid();
	},
	mounted() {
		this.fillUuid();
		this.initCookiesDisclaimerScripts();
		this.checkToken();
		// this.comagic();
		if (window && this.$auth?.user?.id) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ userId: `SITEID-${this.$auth.user.id}` });
		}
	},
	methods: {
		...mapActions({
			initCookiesDisclaimerScripts: 'cookiesDisclaimer/initScripts',
			acceptCookies: 'cookiesDisclaimer/accept',
			rejectCookies: 'cookiesDisclaimer/reject',
		}),
		...mapMutations('authModal', ['setIsVisible']),
		...mapActions('authModal', ['showLogin', 'showRegistration']),
		/*
		comagic() {
			// eslint-disable-next-line no-underscore-dangle
			window.__cs = window.__cs || [];
			// eslint-disable-next-line no-underscore-dangle
			window.__cs.push(['setCsAccount', 'xiLYgaIKan25YvyLs82zABeZWdSIggY_']);
			promiseScript('https://app.comagic.ru/static/cs.min.js');
		},
		*/
		onCookiesAccept() {
			this.acceptCookies(this.$cookies);
		},
		async checkToken() {
			const { $auth, $router, $route } = this;
			const { query } = $route;

			if (query.access_token) {
				const redirect = this.$auth.$storage.getUniversal('redirect');
				let redirectUrl = null;
				if (redirect) {
					redirectUrl = redirect.trim();
					redirectUrl = !redirectUrl || redirectUrl === 'undefined' || redirectUrl === 'null' || redirectUrl === '/undefined/'
						? null : redirectUrl;
					if (redirect !== redirectUrl) {
						$auth.$storage.setUniversal('redirect', redirectUrl);
					}
				}
				$router.push({
					name: $route.name,
					query: {
						...query,
						access_token: undefined,
						refresh_token: undefined,
						token_type: undefined,
						expires_in: undefined,
					},
				});

				await $auth.setStrategy('local');
				await $auth.setUserToken(query.access_token);

				if (query.refresh_token) {
					$auth.setRefreshToken('local', query.refresh_token);
				}

				if (!$auth.user.is_user_confirmed) {
					$auth.$storage.setUniversal('redirect', redirectUrl || '/user/profile');
					$router.push({ name: 'user-confirm', query: undefined });
				} else {
					$auth.$storage.setUniversal('redirect', null);
				}
			}
		},
	},
};
</script>

<style lang="scss">
	@import "common_style";
</style>
