/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 */

class Client {
	/**
	 * @param {AxiosInstance} axios
	 */
	constructor(axios) {
		this.axios = axios;
	}

	// eslint-disable-next-line class-methods-use-this
	mock(data, ms) {
		return new Promise((resolve) => {
			setTimeout(() => resolve(data), ms);
		});
	}

	// eslint-disable-next-line class-methods-use-this
	mockForm(data, errors, ms) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				// eslint-disable-next-line no-magic-numbers
				if (Math.random() > 0.5) {
					resolve(data);
				} else {
					const error = new Error('Validation error');
					error.response = {
						statusText: 'Validation error',
						data: errors,
					};
					console.error({ error });
					reject(error);
				}
			}, ms);
		});
	}

	// eslint-disable-next-line class-methods-use-this
	getErrorMessage(error) {
		const { response } = error;

		if (response) {
			const { statusText, data } = response;
			if (data) {
				const message = data.message || data.error || data.exception;
				return message;
			}
			return statusText;
		}

		throw error;
	}

	// eslint-disable-next-line class-methods-use-this
	getValidationFromError(error) {
		const { response } = error;

		if (response) {
			const { statusText, data } = response;
			if (data) {
				if (data.errors) {
					return { message: null, errors: data.errors };
				}
				const message = data.message
					|| data.error
					|| data.exception
					|| 'Что-то пошло не так';
				return { message, errors: {} };
			}

			return { message: statusText, errors: {} };
		}

		throw error;
	}

	/**
	 * Чекаут-форма / Checkout form
	 */

	async checkoutFormFetch(formId, eventId, sellableProgramIds) {
		return this.axios.$get('/api/checkout/form', {
			params: { formId, eventId, sellableProgramIds },
		});
	}

	async checkoutProductFormFetch(formId) {
		return this.axios.$get(`/api/checkout/product-form/${formId}`);
	}

	async checkoutFormSave(data = {}, options = {}) {
		return this.axios.$post('/api/checkout/form/save', data, options);
	}

	async checkoutFormSaveDocument(data = {}, options = {}) {
		const formData = new FormData();
		Object.keys(data).forEach((key) => {
			formData.append(key, data[key]);
		});
		return this.axios.$post('/api/checkout/upload-file', formData, options);
	}

	async checkoutFormRemoveDocument(file = {}, options = {}) {
		// TODO заменить на урл удаления документа
		return new Promise((resolve, reject) => {
			setTimeout(resolve, 3000);
		});
	}

	async checkoutFormGetFilledData(data = {}, options = {}) {
		return this.axios.$post(
			'/api/checkout/form/get-filled-data',
			data,
			options,
		);
	}

	async getContractData(params = {}, options = {}) {
		return this.axios.$get('/api/checkout/contract', {
			...options,
			params,
		});
	}

	async getContractPdf(params = {}, options = {}) {
		return this.axios.$get('/api/checkout/contract/pdf', {
			...options,
			params,
		});
	}

	async getInvoiceData(params = {}, options = {}) {
		return this.axios.$get('/api/checkout/invoice', { ...options, params });
	}

	async getInvoicePdf(params = {}, options = {}) {
		return this.axios.$get('/api/checkout/invoice/pdf', {
			...options,
			params,
		});
	}

	async saveOrder(data = {}, options = {}) {
		return this.axios.$post('/api/checkout/order/save', data, options);
	}

	async doOnlinePayment(data = {}, options = {}) {
		return this.axios.$post('/api/checkout/payment/create', data, options);
	}

	async doLoanApplication(data = {}, options = {}) {
		return this.axios.$post(
			'/api/checkout/loan-application/create',
			data,
			options,
		);
	}

	async saveInitialLead(data = {}, options = {}) {
		return this.axios.$post(
			'/api/checkout/save-initial-lead',
			data,
			options,
		);
	}

	async getInvoiceStatus(data = {}) {
		return this.axios.$get('/api/checkout/invoice-status', {
			params: data,
		});
	}

	async getPriceData(data = {}) {
		return this.axios.$get('/api/checkout/price-data', { params: data });
	}

	async getPaymentUrl(orderId) {
		return this.axios.$post(`/api/v2/formulate-orders/${orderId}/payment`);
	}

	async getLatestPaymentProvider(orderHash) {
		return this.axios.$get(
			`/api/v2/formulate-orders/${orderHash}/latest-payment-provider`,
		);
	}

	async getCheckoutExists(orderHash) {
		return this.axios.$get(
			`/api/v2/formulate-orders/${orderHash}/checkout-exists`,
		);
	}

	async getCheckoutByOrder(orderHash) {
		return this.axios.$get(
			`/api/v2/formulate-orders/${orderHash}/checkout`,
		);
	}

	async applyPromocode(data = {}, options = {}) {
		return this.axios.$post('/api/checkout/apply-promocode', data, options);
	}

	async removePromocode(data = {}, options = {}) {
		return this.axios.$post(
			'/api/checkout/remove-promocode',
			data,
			options,
		);
	}

	async checkIsStudent(data = {}) {
		return this.axios.$post('/api/checkout/check-is-student', {
			params: data,
		});
	}

	async massUpload(formData) {
		return this.axios.$post('/api/checkout/upload-excel', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	async paymentByInvoice(data = {}, options = {}) {
		return this.axios.$post(
			'/api/checkout/payment-by-invoice',
			data,
			options,
		);
	}

	/**
	 * Узнавание / Recognition
	 */

	async isUserRegistered(data = {}, options = {}) {
		return this.axios.$get(
			'/api/auth/is-registered',
			{ params: data },
			options,
		);
	}

	async koiDbRecognize(data = {}, options = {}) {
		return this.axios.$post('/api/recognition/pre-recognition', data, {
			...options,
			...{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		});
	}

	async requestRecognitionVerification(data = {}, options = {}) {
		return this.axios.post(
			'/api/recognition/request-recognition',
			data,
			options,
		);
	}

	async getRecognitionDataByRecognitionToken(token) {
		if (token) {
			return this.axios.get(
				`/api/recognition/get-user-data-for-verification?token=${token}`,
			);
		}

		return this.axios.get(
			'/api/recognition/get-user-data-for-verification',
		);
	}

	async createRecognizedUser(data = {}, options = {}) {
		return this.axios.$post('/api/recognition/create-user', data, options);
	}

	async updateRecognizedUser(data = {}, options = {}) {
		return this.axios.$post('/api/recognition/update-user', data, options);
	}

	/**
	 * Не отсортировано / Unsorted
	 */

	async createLoanApplication(data = {}, options = {}) {
		return this.axios.$post(
			'/api/v2/loan-applications/create',
			data,
			options,
		);
	}

	async events(params = {}, options = {}) {
		return this.axios.$get('api/event/list', { ...options, params });
	}

	async recommendedEvents(params = {}, options = {}) {
		return this.axios.$get('api/events/recommended', {
			...options,
			params,
		});
	}

	async navigatorEvents(params = {}, options = {}) {
		return this.axios.$get('api/navigator/event/list', {
			...options,
			params,
		});
	}

	async event(slug, options = {}) {
		return this.axios.$get(`api/events/${slug}`, options);
	}

	async eventById(id, options = {}) {
		return this.axios.$get(`api/event/detail/${id}`, options);
	}

	async eventPage(eventSlug, innerPageSlug, options = {}) {
		return this.axios.$get(
			`api/event-page/${eventSlug}/${innerPageSlug}`,
			options,
		);
	}

	async eventAssessments(slug, options = {}) {
		const { data } = await this.axios.$get(
			`api/event/${slug}/assessments/result`,
			options,
		);
		return data;
	}

	async eventCriterias(options = {}) {
		const { data } = await this.axios.$get('api/event/criterias', options);
		return data;
	}

	async assessEvent(slug, assessments) {
		return this.axios.$post(`api/event/${slug}/assessments/assess`, {
			assessments,
		});
	}

	async issue(issue, params = {}, options = {}) {
		const pending = this.axios.$get(`api/issue/${issue}`, {
			...options,
			params,
		});

		pending.then(({ slug }) => {
			if (issue !== slug) {
				throw new Error('404');
			}
		});

		return pending;
	}

	async contentList(params = {}, options = {}) {
		return this.axios.$get('api/content/list', { ...options, params });
	}

	async navigatorContentList(params = {}, options = {}) {
		return this.axios.$get('api/navigator/content/list', {
			...options,
			params,
		});
	}

	async publications(category, person, params = {}, options = {}) {
		const categoryQuery = category ? `&category=${category}` : '';
		const { publications } = await this.axios.$get(
			`/api/publications?person=${person}${categoryQuery}`,
			{
				...options,
				params,
			},
		);
		return publications;
	}

	async contentSources(person, params = {}, options = {}) {
		const { content_sources: data } = await this.axios.$get(
			`/api/content-sources?person=${person}`,
			{
				...options,
				params,
			},
		);

		return data;
	}

	async person(slug, lang = '', params = {}, options = {}) {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`/api/person/${slug}${lang}`, {
			...options,
			params,
		});
	}

	async partner(slug, params = {}, options = {}) {
		return this.axios.$get(`/api/partner/${slug}`, { ...options, params });
	}

	async people(params, options = {}) {
		return this.axios.$get('/api/person/list', { ...options, params });
	}

	async navigatorPeople(params, options = {}) {
		return this.axios.$get('/api/navigator/person/list', {
			...options,
			params,
		});
	}

	async taxonomySections(params, options = {}) {
		const { data } = await this.axios.$get('/api/taxonomy-section/list', {
			...options,
			params,
		});
		return data;
	}

	async taxonomy(params, options = {}) {
		return this.axios.$get('/api/taxonomy', { ...options, params });
	}

	async usefulContentTaxonomy(params, options = {}) {
		return this.taxonomy({ type: 'useful', ...params }, options);
	}

	async mainPage(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/main-page${lang}`);
	}

	async aboutStructure(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/management${lang}`);
	}

	async aboutSustainability(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/sustainability${lang}`);
	}

	async aboutSchool(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/about-school${lang}`);
	}

	async aboutSchoolInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=about-school${lang}`,
		);
	}

	async aboutCrec(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/crec${lang}`);
	}

	async aboutCrecInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=crec${lang}`,
		);
	}

	async aboutGovernance(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/governance-crec${lang}`);
	}

	async donation(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/donation${lang}`);
	}

	async donationInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=donation${lang}`,
		);
	}

	async donatedPeopleCount() {
		return this.axios.$get('api/person/donated/count');
	}

	async alumni(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/alumni${lang}`);
	}

	async alumniInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=alumni${lang}`,
		);
	}

	async alumniOld(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/alumni-old${lang}`);
	}

	async alumniOldInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=alumni-old${lang}`,
		);
	}

	async unsubscribe(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/unsubscribe${lang}`);
	}

	async unsubscribeEmail(email = '') {
		return this.axios.$post(`api/unsubscribed?email=${email}`);
	}

	async loyaltySystem(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/loyalty-system${lang}`);
	}

	async loyaltySystemInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=loyalty-system${lang}`,
		);
	}

	async loyaltyLevels() {
		return this.axios.$get('/api/v2/loyalty/levels');
	}

	async loyaltySystemLk(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/loyaltylk${lang}`);
	}

	async loyaltyB2bSystemLk(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/loyalty-b2b-lk${lang}`);
	}

	async loyaltySystemLkHistory(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/loyalty-history${lang}?parent_slug=loyaltylk${lang}`,
		);
	}

	async loyaltySystemSpecialPromotions(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/special-promotions${lang}?parent_slug=loyaltylk${lang}`,
		);
	}

	async loyaltySystemLkReport(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/soobshit-u-neuchtennyh-aktivnostyah${lang}?parent_slug=loyaltylk${lang}`,
		);
	}

	async loyaltySystemB2bLkReport(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/zadat-vopros-soobshit-o-probleme${lang}?parent_slug=loyaltylk${lang}`,
		);
	}

	async loyaltySystemLkPartners(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/partners-privileges${lang}?parent_slug=loyaltylk${lang}`,
		);
	}

	async loyaltySystemLkBadges(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/badges${lang}?parent_slug=loyaltylk${lang}`,
		);
	}

	async loyaltySystemLkAbout(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/loyalty-system${lang}`);
	}

	async loyaltyB2bSystemLkAbout(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/corporate-programmes${lang}?parent_slug=loyalty-system${lang}`);
	}

	async loyaltySystemLkFAQ(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/faq${lang}?parent_slug=loyalty-system${lang}`,
		);
	}

	async loyaltyEarnAndSpentPoints(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/earn-and-spent-points${lang}?parent_slug=loyaltylk${lang}`,
		);
	}

	async personalDevelopment(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/personal-development${lang}`);
	}

	async personalDevelopmentInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=personal-development${lang}`,
		);
	}

	async digital(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/digital${lang}`);
	}

	async digitalInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=digital${lang}`,
		);
	}

	async privacy(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/privacy${lang}`);
	}

	async privacyInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=privacy${lang}`,
		);
	}

	async probono(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/probono${lang}`);
	}

	async probonoInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=probono${lang}`,
		);
	}

	async unlocking(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/unlocking-territories${lang}`);
	}

	async unlockingInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=unlocking-territories${lang}`,
		);
	}

	async budushee(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/budushee-industrij${lang}`);
	}

	async budusheeInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=budushee-industrij${lang}`,
		);
	}

	async voting(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/voting${lang}`);
	}

	async derzhis(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/derzhis-krepche${lang}`);
	}

	async welcome(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/welcome${lang}`);
	}

	async welcomeInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=welcome${lang}`,
		);
	}

	async istorii(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/istorii-o-budushem${lang}`);
	}

	async trendAward(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/trend-award-2022${lang}`);
	}

	async msb(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/msb${lang}`);
	}

	async msbInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=msb${lang}`,
		);
	}

	async healthcarePrograms(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/healthcare-programs${lang}`);
	}

	async healthcareProgramsInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=healthcare-programs${lang}`,
		);
	}

	async bachelorProgrammes(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/bachelor-programmes${lang}`);
	}

	async bachelorProgrammesInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=bachelor-programmes${lang}`,
		);
	}

	async bachelorsProgrammes(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/bachelors-programmes${lang}`);
	}

	async bachelorsProgrammesInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=bachelors-programmes${lang}`,
		);
	}

	async youngProfessionals(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/young-professionals${lang}`);
	}

	async youngProfessionalsInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=young-professionals${lang}`,
		);
	}

	async leadership(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/leadership-and-personal-transformation${lang}`,
		);
	}

	async leadershipInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=leadership-and-personal-transformation${lang}`,
		);
	}

	async leadershipCompetencyDevelopment(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/leadership-competency-development${lang}`,
		);
	}

	async leadershipCompetencyDevelopmentInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=leadership-competency-development${lang}`,
		);
	}

	async oe(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/operational-efficiency${lang}`);
	}

	async oeInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=operational-efficiency${lang}`,
		);
	}

	async marketing(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/marketing${lang}`);
	}

	async marketingInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=finance${lang}`,
		);
	}

	async finance(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/finance-programs${lang}`);
	}

	async financeInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=finance-programs${lang}`,
		);
	}

	async strategyManagement(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/strategy-management${lang}`);
	}

	async strategyManagementInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=strategy-management${lang}`,
		);
	}

	async researchAndEducation(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/research-and-education${lang}`);
	}

	async researchAndEducationInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=research-and-education${lang}`,
		);
	}

	async communications(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/communications${lang}`);
	}

	async communicationsInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=communications${lang}`,
		);
	}

	async professionalCompetencyDevelopment(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/professional-competency-development${lang}`,
		);
	}

	async professionalCompetencyDevelopmentInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=professional-competency-development${lang}`,
		);
	}

	async highSchoolEnrichmentPrograms(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/school-student${lang}`);
	}

	async highSchoolEnrichmentProgramsInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=school-student${lang}`,
		);
	}

	async personnelManagement(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/personnel-management${lang}`);
	}

	async personnelManagementInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=personnel-management${lang}`,
		);
	}

	async corporate(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/corporate${lang}`);
	}

	async corporateInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=corporate${lang}`,
		);
	}

	async corporateOld(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/corporate-old${lang}`);
	}

	async corporateOldInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=corporate-old${lang}`,
		);
	}

	async b2g(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/b2g-programmes${lang}`);
	}

	async b2gInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=b2g-programmes${lang}`,
		);
	}

	async academic(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/academic${lang}`);
	}

	async academicInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=academic${lang}`,
		);
	}

	async academicCentres(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/test-academic-centres${lang}`);
	}

	async academicCentresInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=test-academic-centres${lang}`,
		);
	}

	async createYourImpact(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/create-your-impact${lang}`);
	}

	async createYourImpactInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=create-your-impact${lang}`,
		);
	}

	async artifactSpace(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/artifact-space${lang}`);
	}

	async artifactSpaceInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=artifact-space${lang}`,
		);
	}

	async inTrend() {
		return this.axios.$get('api/pages/intrend');
	}

	async inTrendInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=inTrend${lang}`,
		);
	}

	async summerSchool() {
		return this.axios.$get('api/pages/videos-summer-school-2020');
	}

	async convent2020() {
		return this.axios.$get('api/pages/convent2020');
	}

	async convent2020InnerPage(slug) {
		return this.axios.$get(`api/pages/${slug}?parent_slug=convent2020`);
	}

	async degree() {
		return this.axios.$get('api/pages/degree');
	}

	async ips() {
		return this.axios.$get('api/pages/ips');
	}

	async hddm() {
		return this.axios.$get('api/pages/hddm');
	}

	async hddmInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/${slug}?parent_slug=hddm${lang}`);
	}

	async tinkoffCredit() {
		return this.axios.$get('api/pages/tinkoff-credit');
	}

	async campus(lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/campus-rental${lang}`);
	}

	async campusInnerPage(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=campus-rental${lang}`,
		);
	}

	async page(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/${slug}${lang}`);
	}

	async center(slug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(`api/pages/${slug}${lang}`);
	}

	async centerInnerPage(slug, parentslug, lang = '') {
		lang = lang === 'en' ? (lang = '-en') : (lang = '');
		return this.axios.$get(
			`api/pages/${slug}${lang}?parent_slug=${parentslug}${lang}`,
		); //
	}

	async openPrograms() {
		return this.axios.$get('api/pages/open-programs');
	}

	async walk() {
		return this.axios.$get('api/pages/walk');
	}

	async openProgramsInnerPage(slug) {
		return this.axios.$get(`api/pages/${slug}?parent_slug=open-programs`);
	}

	async rabota() {
		return this.axios.$get('api/pages/rabota-v-shkole-upravleniya-skolkovo');
	}

	async rabotaInnerPage(slug) {
		return this.axios.$get(`api/pages/${slug}?parent_slug=rabota-v-shkole-upravleniya-skolkovo`);
	}

	async elka() {
		return this.axios.$get('api/elka');
	}

	async agreements() {
		return this.axios.$get('api/agreements');
	}

	async secretSanta() {
		return this.axios.$get('api/pages/secret-santa');
	}

	async partnersProgramme() {
		return this.axios.$get('api/pages/partners-programme');
	}

	async partnersProgrammeInnerPage(slug) {
		return this.axios.$get(
			`api/pages/${slug}?parent_slug=partners-programme`,
		);
	}

	async review(data = {}, options = {}) {
		return this.axios.$post('api/review', data, options);
	}

	async register(data = {}, options = {}) {
		return this.axios.$post('api/auth/register', data, options);
	}

	async registerCorporate(data = {}, options = {}) {
		return this.axios.$post('api/auth/register-corporate', data, options);
	}

	async autologin(data = {}, options = {}) {
		return this.axios.$post('api/auth/autologin', data, options);
	}

	async codeGenerate(data = {}, options = {}) {
		return this.axios.$post('api/auth/code/generate', data, options);
	}

	async codeLogin(data = {}, options = {}) {
		return this.axios.$post('api/auth/code/login', data, options);
	}

	async activate(token) {
		return this.axios.$get(`api/auth/activate/${token}`);
	}

	async contentView(slug) {
		return this.axios.$get(`api/content/view/${slug}`);
	}

	async eventRssView(id) {
		return this.axios.$get(`api/event/rss/${id}/detail`);
	}

	async materialRssView(id) {
		return this.axios.$get(`api/v2/material/rss/${id}/detail`);
	}

	async resendEmail(redirectUri) {
		let url = 'api/auth/activation';
		if (redirectUri) {
			url = `${url}?redirect_uri=${encodeURIComponent(redirectUri)}`;
		}
		return this.axios.$get(url);
	}

	async updateSelf(data = {}, options = {}) {
		return this.axios.$put('api/auth/users/self', data, options);
	}

	async updateSelfAvatar(data = {}, options = {}) {
		return this.axios.$post('api/auth/users/self/avatar', data, options);
	}

	async uploadSelfFile(data = {}, options = {}) {
		return this.axios.$post(
			'api/auth/users/self/upload-file',
			data,
			options,
		);
	}

	async deleteSelfFile(data = {}, options = {}) {
		return this.axios.$post(
			'api/auth/users/self/delete-file',
			data,
			options,
		);
	}

	async userFieldUpload(fieldId, data = {}, options = {}) {
		return this.axios.$post(
			`api/auth/fields/${fieldId}/upload`,
			data,
			options,
		);
	}

	async userFieldDeleteFile(fieldId) {
		return this.axios.$delete(`api/auth/fields/${fieldId}/delete-file`);
	}

	async updatePassword(data = {}, options = {}) {
		return this.axios.$put('api/auth/users/self/password', data, options);
	}

	async getProfileMainFields() {
		return this.axios.$get('api/auth/user/profile/main-fields');
	}

	async getProfileAdditionalFields() {
		return this.axios.$get('api/auth/user/profile/additional-fields');
	}

	async forgotPassword(data = {}, options = {}) {
		return this.axios.$post('/api/password/email', data, options);
	}

	async resetPassword(data = {}, options = {}) {
		return this.axios.$post('/api/password/reset', data, options);
	}

	async getApplicationData(uuid = '') {
		return this.axios.$get(`/api/corona/application/${uuid}`);
	}

	async storeExtendedRegistration(uuid, data = {}, options = {}) {
		return this.axios.$put(`api/corona/application/${uuid}`, data, options);
	}

	async applications(params = {}, options = {}) {
		return this.axios.$get('api/auth/applications', { ...options, params });
	}

	async admissions(params = {}, options = {}) {
		return this.axios.$get('api/auth/admissions', { ...options, params });
	}

	async admissionProgress(id, options = {}) {
		return this.axios.$get(`api/auth/admissions/${id}/progress`, options);
	}

	async admission(id, options = {}) {
		return this.axios.$get(`api/auth/admissions/${id}`, options);
	}

	async admissionStages(id, options = {}) {
		return this.axios.$get(`api/auth/admissions/${id}/stages`, options);
	}

	async getAdmissionStatus(id, params = {}, options = {}) {
		return this.axios.$get(`api/auth/admissions/${id}/status`, {
			...options,
			params,
		});
	}

	async getStageStatus(params = {}, options = {}) {
		return this.axios.$get('api/auth/admissions/stages/get-status', {
			...options,
			params,
		});
	}

	async getNextStageLink(id, options = {}) {
		return this.axios.$get(
			`api/auth/admissions/stages/${id}/get-next-stage-link`,
			options,
		);
	}

	async admissionStage(id, options = {}) {
		return this.axios.$get(`api/auth/admissions/stages/${id}`, options);
	}

	async admissionStageConfirm(id, userId, data = {}, options = {}) {
		return this.axios.$put(
			`api/auth/admissions/stages/${id}/confirm/${userId}`,
			data,
			options,
		);
	}

	async admissionStageSaveData(id, userId, options = {}) {
		return this.axios.$get(
			`api/auth/admissions/stages/${id}/send-notification/${userId}`,
			options,
		);
	}

	async admissionInterview(stageId, data = {}, options = {}) {
		return this.axios.$post(
			`api/auth/admissions/stages/${stageId}/interview`,
			data,
			options,
		);
	}

	async pushes(id, options = {}) {
		return this.axios.$get('api/auth/pushes', options);
	}

	async pushDelete(id, options = {}) {
		return this.axios.$get(`api/auth/pushes/${id}/delete`, options);
	}

	async pushesDeleteAll(id, options = {}) {
		return this.axios.$get('api/auth/pushes/delete-all', options);
	}

	async pushesRestoreAll(id, options = {}) {
		return this.axios.$get('api/auth/pushes/restore-all', options);
	}

	async user(user, params = {}, options = {}) {
		return this.axios.$get(`api/users/${user}`, { ...options, params });
	}

	async users(params = {}, options = {}) {
		return this.axios.$get('api/users', { ...options, params });
	}

	async orders(params = {}, options = {}) {
		return this.axios.$get('api/auth/orders', { ...options, params });
	}

	async progressCourses(params = {}, options = {}) {
		return this.axios.$get('api/auth/progress/courses', {
			...options,
			params,
		});
	}

	async progressMainNumbers(params = {}, options = {}) {
		return this.axios.$get('api/auth/progress/main-numbers', {
			...options,
			params,
		});
	}

	async progressAlumni(params = {}, options = {}) {
		return this.axios.$get('api/auth/progress/alumni', {
			...options,
			params,
		});
	}

	async progressCourseInformation(params = {}, options = {}) {
		return this.axios.$get('api/auth/progress/course-information', {
			...options,
			params,
		});
	}

	async progressPassesPercent(params = {}, options = {}) {
		return this.axios.$get('api/auth/progress/passes-percent', {
			...options,
			params,
		});
	}

	async latestBooking(params = {}, options = {}) {
		return this.axios.$get('api/bookings/latest', { ...options, params });
	}

	async hotels(params = {}, options = {}) {
		return this.axios.$get('api/hotels', { ...options, params });
	}

	async hotelBooking1(data = {}, options = {}) {
		return this.axios.$post('api/bookings/step1', data, options);
	}

	async hotelBooking2(data = {}, options = {}) {
		return this.axios.$post('api/bookings/step2', data, options);
	}

	async hotelBooking3(data = {}, options = {}) {
		return this.axios.$post('api/bookings/step3', data, options);
	}

	async hotelBooking4(data = {}, options = {}) {
		return this.axios.$post('api/bookings/step4', data, options);
	}

	async setRating(data = {}) {
		return this.axios.$post('/api/rating/set', data);
	}

	async getRating(params = {}) {
		return this.axios.$get('/api/rating/current', { params });
	}

	async setReaction(data = {}) {
		return this.axios.$post('/api/reactions/set', data);
	}

	async getReaction(params = {}) {
		return this.axios.$get('/api/reactions/current', { params });
	}

	async vacancies(params = {}, options = {}) {
		return this.axios.$get('api/vacancies', { ...options, params });
	}

	async vacancy(id, options = {}) {
		return this.axios.$get(`api/vacancies/${id}`, options);
	}

	async libraryItems(params = {}, options = {}) {
		return this.axios.$get('api/library', { ...options, params });
	}

	async addLibraryItem(data = {}) {
		return this.axios.$post('api/library', data);
	}

	async delLibraryItem(data = {}) {
		return this.axios.$post('api/library/del', data);
	}

	async formulateOrders(params = {}) {
		return this.axios.$get('api/v2/formulate-orders', { params });
	}

	async formulateOrdersSimple(params = {}) {
		return this.axios.$get('api/v2/formulate-orders/grouped-by-status', { params });
	}

	async orderAbout(hash, data = {}) {
		return this.axios.$get(`/api/v2/formulate-orders/${hash}/about`, data);
	}

	async orderInvoices(hash, data = {}) {
		return this.axios.$get(`/api/v2/formulate-orders/${hash}/invoices`, data);
	}

	async getProfilesClassOfOrder(hash) {
		return this.axios.$get(`/api/v2/formulate-orders/${hash}/students`);
	}

	async inLibrary(params = {}, options = {}) {
		return this.axios.$get('api/library/get', { ...options, params });
	}

	async courses(params = {}, options = {}) {
		return this.axios.$get('api/courses', { ...options, params });
	}

	async course(id, options = {}) {
		return this.axios.$get(`api/courses/${id}`, options);
	}

	async courseLessons(id, options = {}) {
		return this.axios.$get(`api/courses/${id}/lessons`, options);
	}

	async courseProgress(id, options = {}) {
		return this.axios.$get(`api/courses/${id}/progress`, options);
	}

	async lesson(id, options = {}) {
		return this.axios.$get(`api/lessons/${id}`, options);
	}

	async isConfirmed(params = {}, options = {}) {
		return this.axios.$get('api/lessons/is-confirmed', {
			...options,
			params,
		});
	}

	async confirm(data = {}) {
		return this.axios.$post('api/lessons/confirm', data);
	}

	async saveAnswers(data) {
		return this.axios.$post('api/lessons/save-answers', data);
	}

	async getTest(id, options) {
		return this.axios.$get(`api/module/tests/streams/${id}`, options);
	}

	async getStream(streamUrl, options) {
		return this.axios.$get(
			`api/module/tests/streams/get-stream/${streamUrl}`,
			options,
		);
	}

	async comments(params = {}, options = {}) {
		return this.axios.$get('api/comments/list', { ...options, params });
	}

	async saveComment(data = {}, options = {}) {
		return this.axios.$post('api/comments/save', data, options);
	}

	async startTest(streamId, userId, options) {
		return this.axios.$put(
			`api/module/tests/streams/${streamId}/${userId}`,
			options,
		);
	}

	async updateAnswer(data = {}) {
		return this.axios.$post('api/module/tests/streams/update-answer', data);
	}

	async deleteAnswer(data = {}) {
		return this.axios.$post('api/module/tests/streams/delete-answer', data);
	}

	async getResults(id, options) {
		return this.axios.$get(
			`api/module/tests/streams/get-results/${id}`,
			options,
		);
	}

	async restartTest(id, options) {
		return this.axios.$get(
			`api/module/tests/streams/restart/${id}`,
			options,
		);
	}

	async addToTest(streamUrl, admissionStageId = null, options) {
		const stageId = admissionStageId
			? `auth_admission_stage_id=${admissionStageId}`
			: '';

		return this.axios.$get(
			`api/module/tests/streams/add/${streamUrl}?${stageId}`,
			options,
		);
	}

	async sendTestResult(params = {}) {
		return this.axios.$post(
			'api/module/tests/streams/send-results',
			params,
		);
	}

	async sendLeadStream(streamToUserId, data = {}) {
		return this.axios.$post(
			`api/module/tests/streams/send-lead/${streamToUserId}`,
			data,
		);
	}

	async updateLeadStream(streamToUserId, data = {}) {
		return this.axios.$post(
			`api/module/tests/streams/update-lead/${streamToUserId}`,
			data,
		);
	}

	async endTest(streamToUserId) {
		return this.axios.$get(
			`api/module/tests/streams/end-test/${streamToUserId}`,
		);
	}

	async streams(params = {}, options = {}) {
		return this.axios.$get('api/module/tests/streams', {
			...options,
			params,
		});
	}

	async storeStage({ streamToUserId, groupId }, params = {}, options = {}) {
		return this.axios.$get(
			`api/module/tests/streams/store-stage/${streamToUserId}/${groupId}`,
			{ ...options, params },
		);
	}

	async getPrograms(query) {
		try {
			return this.axios.$get(`api/users/search-program?name=${query}`);
		} catch (error) {
			console.error(error);
			// non-POJOs so rethrow
			throw new Error(error.message);
		}
	}

	async programmes() {
		return this.axios.$get('api/pages/programmes');
	}

	async feedbackRequest(data) {
		return this.axios.$post('api/v2/backoffice/feedback/save', data);
	}

	async industriesList(language) {
		return this.axios.$get(`api/industries?language=${language}`);
	}

	async getDirectory(directory, language) {
		return this.axios.$get(
			`api/directories/${directory}?language=${language}`,
		);
	}

	async alumniRequest(data, additionalParams = {}) {
		return this.axios.$get(
			`api/auth/users/self/request-alumni?uri=${data}`,
			{
				params: additionalParams,
			},
		);
	}

	async submitFormulateForm(scenario, data) {
		return this.axios.$post(
			`api/v2/formulate/forms/scenarios/${scenario}/submit`,
			data,
		);
	}

	async checkPromocode(data) {
		return this.axios.$post('api/formulate/check-promocode', data);
	}

	async updateProfile(data) {
		return this.axios.$post('api/v2/formulate-user-profiles/update', data);
	}

	async products(params = {}, options = {}) {
		return this.axios.$get('api/v2/user/products', {
			...options,
			params,
		});
	}

	async orderInfo(hash, data = {}) {
		return this.axios.$get(`/api/v2/formulate-orders/${hash}`, data);
	}

	async managersOfOrder(hash) {
		return this.axios.$get(`/api/v2/formulate-orders/${hash}/consultants`);
	}

	async sendDocumentsToVerify(hash) {
		return this.axios.$post(
			`/api/v2/formulate-orders/${hash}/send-documents-to-verify`,
		);
	}

	async uploadFormulateProfilePhoto(data) {
		return this.axios.$post(
			'/api/v2/formulate-user-profiles/uploadPhoto',
			data,
		);
	}

	async changeAdmissionStatus(admissionId, status) {
		return this.axios.$post(
			`api/auth/admissions/${admissionId}/change-status`,
			{ status },
		);
	}

	async academicBlocks(params) {
		return this.axios.$get(
			'/api/v2/educational-track-constructor/academic-blocks',
			{ params },
		);
	}

	async academicBlocksId() {
		return this.axios.$get(
			'/api/v2/educational-track-constructor/academic-blocks',
		);
	}

	async academicBlocksFiltered(topicId, isOnline, page) {
		const param = isOnline ? 'online=1' : 'offline=1';
		return this.axios.$get(
			`/api/v2/educational-track-constructor/academic-blocks?discipline=${topicId}&${param}&page=${page}`,
		);
	}

	async expertBlocks() {
		return this.axios.$get('/api/v2/industries');
	}

	async energyBlocks(params) {
		return this.axios.$get(
			'/api/v2/educational-track-constructor/energy-blocks',
			{ params },
		);
	}

	async energyBlocksId(id) {
		return this.axios.$get(
			`/api/v2/educational-track-constructor/energy-blocks/${id}`,
		);
	}

	async constructorPresets(query) {
		return this.axios.$get(
			'/api/v2/educational-track-constructor/presets',
			{ params: query },
		);
	}

	async constructorPresetId(id) {
		return this.axios.$get(
			`/api/v2/educational-track-constructor/presets/${id}/data`,
		);
	}

	async constructorAddPresets(data) {
		await this.axios
			.$post('/api/v2/educational-track-constructor/presets', data)
			.then((response) => {
				// console.log('res', response);
			});
	}

	async additionalParameters() {
		return this.axios.$get(
			'/api/v2/educational-track-constructor/additional-parameters',
		);
	}

	async ParticipantLevelCollection() {
		return this.axios.$get(
			'api/v2/educational-track-constructor/participant-levels',
		);
	}

	async ParticipantNumbersCollection() {
		return this.axios.$get(
			'api/v2/educational-track-constructor/participant-numbers',
		);
	}

	async disciplines() {
		return this.axios.$get('api/v2/disciplines');
	}

	async getFormulateFormById(id) {
		return this.axios.$get(`api/v2/formulate/form/${id}`);
	}

	async getPDFofPreset(id) {
		return this.axios.$get(
			`/api/v2/educational-track-constructor/presets/${id}/pdf`,
			{ responseType: 'blob' },
		);
	}

	async getScenarioSuccessText(scenario) {
		return this.axios.$get(
			`/api/v2/formulate/forms/scenarios/${scenario}/success-text`,
		);
	}

	async getFormulateProfile() {
		return this.axios.$get('/api/v2/formulate-form/user-data');
	}

	async getUserProfile() {
		return this.axios.$get('/api/v2/user/profile');
	}

	async getTextOkSuccess(hash) {
		return this.axios.$get(`api/v2/formulate-orders/${hash}/success-text`);
	}

	async getUserProfileFields(params) {
		return this.axios.$get(`/api/v2/user/profile-new?${params}`);
	}

	async setUserProfileFields(data) {
		return this.axios.$post('/api/v2/user/profile', data);
	}

	async setAgreementFields(data, userId) {
		await this.axios.$post(
			`/api/v2/user/${userId}/set-agreement-fields`,
			data,
		);
	}

	async fetchUserByHash(data) {
		return this.axios.$post('/api/v2/user/fetch-user-by-hash', data);
	}

	async setAgreementFieldsByHash(data) {
		return this.axios.$post(
			'/api/v2/user/set-agreement-fields-by-hash',
			data,
		);
	}

	async getLoyaltyProfile() {
		return this.axios.$get('/api/v2/loyalty/status');
	}

	async getSumProducts(products) {
		return this.axios.$post('/api/v2/formulate/logic/calc', products);
	}

	async getIsVerifiedPhone() {
		return this.axios.$get('api/v2/verify-phone/verified');
	}

	async getIsVerifies() {
		return this.axios.$get('api/v2/verify-phone/verifies');
	}

	async getLoyaltyLevels() {
		return this.axios.$get('/api/v2/loyalty/levels');
	}

	async getLoyaltyMaxPointsToCharge(amount) {
		return this.axios.$get('/api/v2/loyalty/max-points-charge', {
			params: {
				amount,
			},
		});
	}

	async loyaltyPromocodesGenerate(data = {}, options = {}) {
		return this.axios.$post('/api/v2/loyalty/promocodes/generate', data, options);
	}

	async getEventTaxonomies(lang = 'ru') {
		return this.axios.$get('/api/v2/navigator/events/taxonomies', {
			params: {
				lang,
			},
		});
	}

	async getEventTaxonomiesGroups(lang = 'ru', query = {}) {
		return this.axios.$get('/api/v2/navigator/events/groups', {
			params: {
				lang,
				...query,
			},
		});
	}

	async getEvents(lang = 'ru', params) {
		return this.axios.$get('/api/v2/navigator/events', {
			params: {
				...params,
				lang,
			},
		});
	}

	async getUsefulContentTaxonomiesGroups(lang = 'ru', query = {}) {
		return this.axios.$get('/api/v2/navigator/content/groups', {
			params: {
				lang,
				...query,
			},
		});
	}

	async getUsefulContent(lang = 'ru', params) {
		return this.axios.$get('/api/navigator/content', {
			params: {
				...params,
				lang,
			},
		});
	}

	async getEventsWithPrices(params = {}) {
		return this.axios.$get('/api/v2/navigator/events-with-prices', { params });
	}

	async getDonationPage(hash) {
		return this.axios.$get(`/api/v2/donation-pages/${hash}`);
	}

	async getProviders(hash) {
		return this.axios.$get(`/api/v2/billing/orders/${hash}/providers`);
	}

	async createPaymentFromOrder(hash, data) {
		return this.axios.$post(
			`api/v2/formulate-orders/${hash}/create-payment-directly`,
			data,
		);
	}

	async createCheckoutForIndividual(data) {
		return this.axios.$post('/api/v2/billing/checkout/person', data);
	}

	async createCheckoutForUridic(data) {
		return this.axios.$post('/api/v2/billing/checkout/company', data);
	}

	async checkProgramAccess(pageID) {
		return this.axios.$get(`/api/v2/pages/${pageID}/check-program-access`);
	}

	async getVerificationStatusDocuments() {
		return this.axios.$get('/api/v2/user/documents-verification');
	}

	async sendDocumentsOnVerify(data) {
		return this.axios.$post('/api/v2/user/documents-verification', data);
	}

	async sendAccessProgramRequest(id) {
		return this.axios.$post('/api/v2/user/programs/send-access-request', id);
	}

	async deleteOrder(hash) {
		return this.axios.$delete(`/api/v2/user/formulate-orders/${hash}`);
	}

	async issueVatInvoice(hash) {
		return this.axios.$post(`/api/v2/user/formulate-orders/${hash}/vat`);
	}

	async admissionApplication(admission, data) {
		return this.axios.$post(`api/v2/admissions/${admission}/application`, data);
	}

	async payByInvoice(invoice, data) {
		return this.axios.$post(`/api/v2/billing/invoices/${invoice.reference}/pay`, data);
	}

	async getTransactionInfoByProgram(productId, price, isCharge = false, isFormulate = true, forcePhoneVerify = false) {
		return this.axios.$post('/api/v2/loyalty/transaction', {
			product_id: productId,
			price_per_product: price,
			is_charge: isCharge,
			is_formulate: isFormulate, // true - FormulateProduct, false - SellableProgram
			force_phone_verification: forcePhoneVerify,
		});
	}

	async getFormulateProductQrInfo(hash) {
		return this.axios.$get(`/api/v2/formulate-user-qrs/${hash}`);
	}

	async getQuantityOfProduct(hash, data) {
		return this.axios.$post(`/api/v2/formulate-user-qrs/${hash}/get-quantity`, data);
	}

	async activateFormulateQr(hash, data) {
		return this.axios.$post(`/api/v2/formulate-user-qrs/${hash}/activate`, data);
	}

	async userExists(data) {
		return this.axios.$post('/api/v2/users/exists', data);
	}

	async getDirectoryProfile() {
		return this.axios.$get('/api/v2/user/directory/profile');
	}

	async getIndustries() {
		return this.axios.$get('api/v2/user/directory/industries');
	}

	async getHasAccessToDirectory() {
		return this.axios.$get('/api/v2/user/directory/has-access');
	}

	async setRequestAccessToDirectory(data) {
		return this.axios.$post('/api/v2/user/directory/request-access', data);
	}

	async getDirectoryProgrammes(query) {
		return this.axios.$get('api/v2/user/directory/programmes', {
			params: {
				...query,
			},
		});
	}

	async getDirectoryCompanies(query) {
		return this.axios.$get('api/v2/user/directory/companies', {
			params: {
				...query,
			},
		});
	}

	async getDirectoryCities(query) {
		return this.axios.$get('api/v2/user/directory/cities', {
			params: {
				...query,
			},
		});
	}

	async getDirectoryCountries(query) {
		return this.axios.$get('api/v2/user/directory/countries', {
			params: {
				...query,
			},
		});
	}

	async getDirectoryRegions(query) {
		return this.axios.$get('api/v2/user/directory/regions', {
			params: {
				...query,
			},
		});
	}

	async getDirectoryUsers(query) {
		return this.axios.$get('api/v2/user/directory/users', {
			params: {
				...query,
			},
		});
	}

	async getDirectoryIndustries(query) {
		return this.axios.$get('/api/v2/user/directory/industries', {
			params: {
				...query,
			},
		});
	}

	async getDirectoryClubs(query) {
		return this.axios.$get('api/v2/user/directory/clubs', {
			params: {
				...query,
			},
		});
	}

	async getDirectoryFlows(query) {
		return this.axios.$get('api/v2/user/directory/programmes-flows', {
			params: {
				...query,
			},
		});
	}

	async getDirectoryCard(id, type) {
		return this.axios.$get(`api/v2/user/directory/cards/${id}?type=${type}`);
	}

	async sentPrivateMessage(id, params) {
		return this.axios.$post(`/api/v2/user/directory/send-private-mail-to/${id}`, params);
	}

	async getBadges() {
		return this.axios.$get('api/v2/loyalty/badges');
	}

	async getUsersProgrammes() {
		return this.axios.$get('api/v2/user/flows');
	}

	async getUsersVisibility(params) {
		return this.axios.$get(`api/v2/user/visibility?${params}`);
	}

	async setUsersVisibility(data) {
		return this.axios.$post('api/v2/user/visibility', data);
	}

	async getClubs(search) {
		return this.axios.$get(`api/v2/multiselect/clubs?search=${search}`);
	}

	async getEventsByRange(start, end, blockId) {
		return this.axios.$get('api/v2/calendar/events-by-month', {
			params: {
				start,
				end,
				blockId,
			},
		});
	}

	async checkExistAlumniSpace() {
		return this.axios.$get('api/reservation/create');
	}

	async getReservations(start, end) {
		return this.axios.$get('/api/reservation/calendar', {
			params: {
				start,
				end,
			},
		});
	}

	async editReservation(id, data) {
		return this.axios.$put(`/api/reservation/calendar/${id}`, data);
	}

	async getCategories(search) {
		return this.axios.$get(`api/v2/multiselect/categories?search=${search}`);
	}

	async getCatalogs(type, search) {
		return this.axios.$get(`api/v2/multiselect/catalog?type=${type}&search=${search}`);
	}

	async getMultiselectProducts(search) {
		return this.axios.$get(`api/v2/multiselect/formulate-products?search=${search}`);
	}

	async getElectives(start, end, product_id, get_remote = 1) {
		return this.axios.$get('/api/elective/electives-calendar', {
			params: {
				start,
				end,
				product_id,
				get_remote,
			},
		});
	}

	async getElectivesListFormat(product_id, get_remote = 1) {
		return this.axios.$get('api/elective/electives-list', {
			params: {
				product_id,
				get_remote,
			},
		});
	}

	async getElectivesNewListFormat(product_id) {
		return this.axios.$get('/api/elective/user-electives-list', {
			params: {
				product_id,
			},
		});
	}

	async getElectivesAvailability(start, product_id) {
		return this.axios.$get('/api/elective/check-available-electives', {
			params: {
				start,
				product_id,
			},
		});
	}

	async getElectivesByUser(product_id) {
		return this.axios.$get('/api/elective/user-electives', {
			params: {
				product_id,
			},
		});
	}

	async getElectivesSimpleByUser(product_id) {
		return this.axios.$get('/api/elective/user-electives-simple', {
			params: {
				product_id,
			},
		});
	}

	async signUpForElective(data) {
		return this.axios.$post('/api/elective/order-create', data);
	}

	async signUpForElectiveSimple(data) {
		return this.axios.$post('/api/elective/order-create-simple', data);
	}

	async putElectiveStatus(order, data) {
		return this.axios.$put(`/api/elective/order-by-user/${order}`, data);
	}

	async cancelElectiveRequest(data) {
		return this.axios.$post('/api/elective/cancel-order-simple', data);
	}

	async pagesBase(slug, options = {}) {
		return this.axios.$get(`api/main-sections/${slug}`, options);
	}

	async pagesBaseInner(parentSlug, slug, options = {}) {
		return this.axios.$get(`api/sub-sections/${parentSlug}/${slug}`, options);
	}

	async getTagsRecommendations() {
		return this.axios.$get('api/v2/navigator/recommendations/filters/all');
	}

	async getUserRecommendations(data) {
		return this.axios.$post('api/v2/navigator/recommendations', data);
	}

	async sendPersonalBadgeRequest(data = {}, options = {}) {
		return this.axios.$post(
			'api/v2/loyalty/personal-badge',
			data,
			options,
		);
	}

	async getUserSso() {
		return this.axios.$get('sso/user');
	}

	async sendApplicationWithoutAdmission(hash, data) {
		return this.axios.$post(`api/v2/${hash}/application-store`, data);
	}

	async getScheduleCalendar(start, end, hash) {
		return this.axios.$get('/api/schedule/calendar', {
			params: {
				start,
				end,
				hash,
			},
		});
	}

	async sendEnableRecurringPayment(hash, data) {
		return this.axios.$post(
			`api/v2/formulate-orders/${hash}/toggle-recurring-payment`,
			data,
		);
	}

	async getDashboardWidget(widgetName, params = {}) {
		return this.axios.$get(`api/widgets/${widgetName}/data`, {
			params,
		});
	}

	async getMarks() {
		return this.axios.$get('/api/marks');
	}
}

export default Client;
